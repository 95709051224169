import { groq } from "next-sanity";

export const refMedia = `
  video { 
    ...,
    asset->{ data, playbackId, thumbTime }
  }, 
  image {
    ...,
    asset->{ 
      ...,
      _id,
      metadata { lqip, dimensions, palette {lightMuted{background}} }
    }
  }
`;

export const refMediaMini = `
  video { 
    ...,
    asset->{ data, playbackId }
  }, 
  image {
    ...,
    asset->{ 
      ...,
      _id,
      metadata { dimensions }
    }
  }
`;

export const blockGroup = `
  _type == "blockGroup" => {
    ...,
    mode == 'manual' => {
      "artists": artists[]->{...,thumbnail {${refMedia}},},
      "events": events[]->{...,thumbnail {${refMedia}},},
    },
    mode == 'auto' => {
      "artists": *[_type == 'artist'],
      "events": *[_type == 'event']{..., calendarEntry->},
    },
    
  }
`;
export const blockMap = `
  _type == "blockMap" => {
    ...,
    "venues": *[_type == "venue"][]
  }
`;
export const blocks = `
  ${blockGroup},
  ${blockMap}
`;

export const homePageQuery = groq`
  *[_type == "home"][0]{
  }
`;
